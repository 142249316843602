import React, { PureComponent } from 'react';
import RentableCell from '../rentable-cell';
import InactivePeriodCell from './InactivePeriodCell';
import InactiveRentableCell from './InactiveRentableCell';
import calculateRentableCellStyle from './calculateRentableCellStyle';
import classes from './RentableIdentityCell.module.css';
import { DateRange } from 'moment-range';
import { RentableIdentityData, RentableData } from '../rentable-identity-cells';
import momentRange from '../../utils/momentRange';
import moment from 'moment';
import serializeDate from '../../utils/serializeDate';
import flatMap from 'lodash/flatMap';

interface RentableIdentityCellProps {
  rentableIdentity: RentableIdentityData;
  period: DateRange;
}

export default class RentableIdentityCell extends PureComponent<RentableIdentityCellProps> {
  get inactiveRentables() {
    let { rentableIdentity } = this.props;
    return rentableIdentity.rentables.filter((rentable) => !rentable.forRental);
  }

  get activeRentables() {
    let { rentableIdentity } = this.props;
    return rentableIdentity.rentables.filter((rentable) => rentable.forRental);
  }

  renderInactivePeriodCells() {
    const { rentableIdentity, period } = this.props;

    if (rentableIdentity.rentables.length === 0) {
      return <InactivePeriodCell style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }} />;
    } else {
      // Iterates over rentables (inactive and active) and substracts them from the period
      const reducer = (dateRanges: DateRange[], rentable: RentableData) => {
        const rentableRange = momentRange(moment(rentable.activeFrom), moment(rentable.activeTill || period.end));
        return flatMap(dateRanges, (dateRange) => dateRange.subtract(rentableRange));
      };

      // Fill up empty spaces with <InactivePeriodCell />'s (means no rentable exists at that point in time)
      return rentableIdentity.rentables.reduce(reducer, [period]).map(({ start, end }) => {
        const activeFrom = start.isSame(period.start) ? start.clone().subtract(0.5, 'day') : start;
        const activeTill = end;

        const style = calculateRentableCellStyle({ rentable: { activeFrom, activeTill }, period });
        return <InactivePeriodCell key={serializeDate(start)} style={style} />;
      });
    }
  }

  renderInactiveRentableCells() {
    return this.inactiveRentables.map((rentable) => {
      let style = calculateRentableCellStyle({ rentable, period: this.props.period });
      return <InactiveRentableCell key={rentable.id} style={style} />;
    });
  }

  renderRentableCells() {
    let { period } = this.props;

    return this.activeRentables.map((rentable) => {
      let style = calculateRentableCellStyle({ rentable, period });
      return <RentableCell key={rentable.id} rentable={rentable} period={period} style={style} />;
    });
  }

  render() {
    return (
      <div className={classes.root}>
        {this.renderInactivePeriodCells()}
        {this.renderInactiveRentableCells()}
        {this.renderRentableCells()}
      </div>
    );
  }
}
