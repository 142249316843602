import React, { PureComponent } from 'react';
import classes from './InactiveRentableCell.module.css';

interface InactiveRentableCellProps {
  style: React.CSSProperties;
}

export default class InactiveRentableCell extends PureComponent<InactiveRentableCellProps> {
  render() {
    let { style } = this.props;
    return <div className={classes.root} style={style} title="Niet in de verhuur" />;
  }
}
