import { createConsumer } from '@rails/actioncable';
import ActionCableLink from 'graphql-ruby-client/subscriptions/ActionCableLink';

const websocketProtocol = window.location.protocol === 'https:' ? 'wss' : 'ws';
const websocketHost = process.env.REACT_APP_ENV === 'development' ? 'app.local.bookingexperts.nl:3000' : window.location.host;
const websocketUrl = `${websocketProtocol}://${websocketHost}/cable`;

const cable = createConsumer(websocketUrl);
const channelName = 'Planboard::SubscriptionsChannel';
const actionCableSocketLink = new ActionCableLink({ cable, channelName });

export const consumer = cable;
export default actionCableSocketLink;
