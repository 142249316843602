export interface ReservationData {
  id: string;
  startDate: string;
  endDate: string;
  departureDate: string;
  lateCheckout: boolean;
  fixedRentable: boolean;
  url: string;
  rentableId: string;

  booking: {
    id: string;
    referenceNr: string;
    source: string;
    isOption: boolean;
    isCancelled: boolean;
    stateDescription: string;
    bookingNr: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    company: string;

    // Alerts display mode
    hasUninvoicedAmountForGuest?: boolean;
    isConfirmed?: boolean;
    isTemporary?: boolean;
    hasUnfinishedTodos?: boolean;
    hasUnpaidAmountForGuest?: boolean;
    hasPastDeadlineAmountForGuest?: boolean;

    channel: {
      id: string;
      name: string;
    };
  };

  // Presence display mode
  checkedInOn?: string;
  checkedOutOn?: string;
  isCheckedIn?: boolean;
  isCheckedOut?: boolean;
}

export type AgendaPeriodData =
  | IcalAgendaPeriodData
  | MaintenanceAgendaPeriodData
  | OwnerMaintenanceAgendaPeriodData
  | NotEnoughStockAgendaPeriodData
  | BlockedByGroupedRentableTypeAgendaPeriodData
  | ReservationAgendaPeriodData;

export enum AgendaPeriodType {
  IcalAgendaPeriod = 'IcalAgendaPeriod',
  MaintenanceAgendaPeriod = 'MaintenanceAgendaPeriod',
  OwnerMaintenanceAgendaPeriod = 'OwnerMaintenanceAgendaPeriod',
  NotEnoughStockAgendaPeriod = 'NotEnoughStockAgendaPeriod',
  BlockedByGroupedRentableTypeAgendaPeriod = 'BlockedByGroupedRentableTypeAgendaPeriod',
  ReservationAgendaPeriod = 'ReservationAgendaPeriod',
}

export interface IcalAgendaPeriodData {
  id: string;
  __typename: AgendaPeriodType.IcalAgendaPeriod;
  startDate: string;
  endDate: string;
  memo: string;
  url: string;
  icalChannel: {
    id: string;
    name: string;
  };
}

export interface MaintenanceAgendaPeriodData {
  id: string;
  __typename: AgendaPeriodType.MaintenanceAgendaPeriod;
  startDate: string;
  endDate: string;
  memo: string;
  editUrl: string;
}

export interface OwnerMaintenanceAgendaPeriodData {
  id: string;
  __typename: AgendaPeriodType.OwnerMaintenanceAgendaPeriod;
  startDate: string;
  endDate: string;
  memo: string;
  editUrl: string;
}

export interface NotEnoughStockAgendaPeriodData {
  id: string;
  __typename: AgendaPeriodType.NotEnoughStockAgendaPeriod;
  startDate: string;
  endDate: string;
}

export interface BlockedByGroupedRentableTypeAgendaPeriodData {
  id: string;
  __typename: AgendaPeriodType.BlockedByGroupedRentableTypeAgendaPeriod;
  startDate: string;
  endDate: string;
  blockingReservation: {
    id: string;
    url: string;
    booking: {
      referenceNr: string;
      bookingNr: string;
      firstName: string;
      lastName: string;
      company: string;
    };
  };
}

export interface ReservationAgendaPeriodData {
  id: string;
  __typename: AgendaPeriodType.ReservationAgendaPeriod;
  startDate: string;
  endDate: string;
  reservation: ReservationData;
}

export interface RentableData {
  id: string;
  rentableIdentityId: string;
  activeFrom: string;
  activeTill: string | null;
  forRental: boolean;
  agendaPeriods: AgendaPeriodData[];
}

export interface RentableIdentityData {
  id: string;
  rentables: RentableData[];
}

export default interface RentableIdentityCellsQueryData {
  rentableIdentities: RentableIdentityData[];
}
