import React from 'react';
import { DateInput as OriginalDateInput } from '@blueprintjs/datetime';
import { PERIODS } from '../../config/planboard';
import moment from 'moment';
import Translation from '../translation';
import MomentLocaleUtils from 'react-day-picker/moment';

// All planboard users (en or nl) use Monday as the first day of the week
moment.updateLocale('en', { week: { dow: 1 } });

function getFormat(locale: string) {
  switch (locale) {
    case 'nl':
      return 'DD-MM-YYYY';
    case 'de':
      return 'DD.MM.YYYY';
    default:
      return 'YYYY-MM-DD';
  }
}

function formatDate(date: Date, locale: string) {
  // prettier-ignore
  return moment(date).locale(locale).format(getFormat(locale));
}

function parseDate(str: string, locale: string) {
  // prettier-ignore
  return moment(str, getFormat(locale)).locale(locale).toDate();
}

interface DateInputProps {
  value?: Date;
  defaultValue?: Date;
  disabled?: boolean;
  onChange?: (selectedDate: Date, isUserChange: boolean) => void;
}

export default React.forwardRef<OriginalDateInput, DateInputProps>((props, ref) => {
  const minDate = PERIODS[0].start.toDate();
  const maxDate = PERIODS[PERIODS.length - 1].start.toDate();

  return (
    <Translation>
      {(_translate, { lng }) => (
        <OriginalDateInput
          formatDate={formatDate}
          parseDate={parseDate}
          locale={lng}
          localeUtils={MomentLocaleUtils}
          minDate={minDate}
          maxDate={maxDate}
          popoverProps={{ targetClassName: 'block' }}
          ref={ref}
          {...props}
        />
      )}
    </Translation>
  );
});
