import React from 'react';
import Sidebar from '../sidebar';
import Translation from '../translation';

interface SummarySectionProps {
  rentableIdentity: {
    name: string;
    rentableTypeName: string;
    priorityDescription: string;

    currentRentable: null | {
      rentableType: {
        name: string;
        url: string;

        rentableSegment: {
          id: string;
          name: string;
        };
      };
    };
  };
}

export default function SummarySection({ rentableIdentity }: SummarySectionProps) {
  const { currentRentable, rentableTypeName } = rentableIdentity;
  const rentableType = currentRentable ? currentRentable.rentableType : { name: rentableTypeName, url: undefined };
  const rentableSegmentName = currentRentable ? currentRentable.rentableType.rentableSegment.name : undefined;

  return (
    <Translation>
      {(translate) => (
        <Sidebar.Section title={translate('Summary')}>
          <Sidebar.Table>
            <Sidebar.TableRow header={translate('Number')} data={rentableIdentity.name} />
            <Sidebar.TableRow header={translate('Type')} data={<a href={rentableType.url}>{rentableType.name}</a>} />
            <Sidebar.TableRow header={translate('Segment')} data={rentableSegmentName} />
            <Sidebar.TableRow header={translate('Priority')} data={rentableIdentity.priorityDescription} />
          </Sidebar.Table>
        </Sidebar.Section>
      )}
    </Translation>
  );
}
