export type RowData = RentableSegmentRowData | RentableTypeRowData | RentableIdentitiesRowData | RentablesRowData;

export enum RowType {
  RentableSegmentRow = 'RentableSegmentRow',
  RentableTypeRow = 'RentableTypeRow',
  RentableIdentitiesRow = 'RentableIdentitiesRow',
  RentablesRow = 'RentablesRow',
}

export interface RentableSegmentRowData {
  id: string;
  type: RowType.RentableSegmentRow;
  rentableSegment: RentableSegmentData;
}

export interface RentableTypeRowData {
  id: string;
  type: RowType.RentableTypeRow;
  rentableType: RentableTypeData;
}

interface RentableIdentitiesRowData {
  id: string;
  type: RowType.RentableIdentitiesRow;
  rentableIdentities: RentableIdentityData[];
  rentableTypeId: string;
}

interface RentablesRowData {
  id: string;
  type: RowType.RentablesRow;
  rentables: RentableData[];
}

export interface UserData {
  id: string;
  planboardStartDate: string | null;
  planboardLayout: string;
}

export interface RentableSegmentData {
  id: string;
  name: string;
}

export interface RentableTypeData {
  id: string;
  name: string;
  reference: string;
}

export interface RentableIdentityData {
  id: string;
  name: string;
  priority: number;
  blockedForExternalReservations: boolean;
  blockingTodosCount: number;
  petsAllowed: boolean;
  url: string;
  rentableIds: string[];
}

export interface RentableData {
  id: string;
  petsAllowed: boolean;

  rentableIdentity: {
    id: string;
    name: string;
    priority: number;
    blockedForExternalReservations: boolean;
    blockingTodosCount: number;
    url: string;
  };

  rentableType: {
    id: string;
    name: string;
  };
}

export default interface GridQueryData {
  user: UserData;
  rows: RowData[];
}
